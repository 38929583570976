import { Col, Container, Row } from "react-bootstrap";

const SectionContact = () => {
  return (
    <Container fluid>
      <Row>
        <Col xs={1} lg={2} />
        <Col xs={10} lg={8} className="sectionContact">
          <a
            className="contactLink"
            href="https://www.linkedin.com/in/jin-hoe-lim/"
          >
            L I N K E D I N
          </a>
          <br />
          <a
            className="contactLink"
            href="https://digital-bath.s3.ap-southeast-2.amazonaws.com/curriculum-vitae-jin.pdf"
          >
            C U R R I C U L U M &nbsp; V I T A E
          </a>

          <br />
          <a className="contactLink" href="mailto:contact@digital-bath.net">
            C O N T A C T
          </a>
          <br style={{ margin: "3em" }} />
          <div className="contactText">available for new opportunities</div>
        </Col>
        <Col xs={1} lg={2} />
      </Row>
    </Container>
  );
};
export default SectionContact;
